import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EventDetailsModal from '@app/components/apps/change-logs/EventDetailsModal';
import { AllHistory } from '@app/components/apps/recommendation-history/AllHistory';
import { Assignments } from '@app/components/apps/recommendation-history/Assignments';
import { ConfigurationChanges } from '@app/components/apps/recommendation-history/ConfigurationChanges';
import { Exemptions } from '@app/components/apps/recommendation-history/Exemptions';
import AssignRecommendationModal from '@app/components/apps/recommendations/AssignRecommendationModal';
import ExemptRecommendationModal from '@app/components/apps/recommendations/ExemptRecommendationModal';
import { useRecommendationTableColumnsAndActions } from '@app/components/apps/recommendations/hooks/useRecommendationTableCols';
import MarkComplianceModal from '@app/components/apps/recommendations/MarkComplianceModal';
import RecommendationDetailsModal from '@app/components/apps/recommendations/RecommendationDetailsModal';
import { BasePageWrapper } from '@app/components/common/BasePageWrapper/BasePageWrapper';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import CloudSyncIcon from '@app/components/icons/cloud-sync/CloudSync';
import SeverityBadge from '@app/components/tables/AntdTableWrapper/components/SeverityBadge';
import {
  ChangeTypeBadge,
  ComplianceStatusBadge,
  ExemptionStatusBadge,
} from '@app/components/tables/AntdTableWrapper/components/StatusBadge';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useDataTable } from '@app/components/tables/data-table/hooks/useDataTable';
import { useTableFiltersV2 } from '@app/components/tables/data-table/hooks/useTableFilters';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { RootState } from '@app/store/store';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { TRecommendationHistory } from '@app/types/recommendationHistory';
import { capitalize, stripHtml } from '@app/utils/utils';
import { Popover, Tabs } from 'antd';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { RangeValue } from 'rc-picker/lib/interface';
import { useSelector } from 'react-redux';

export const DATE_DATA_INDEX = 'date';

export interface TEventLogs {
  labels: {
    becameNonCompliantText: string;
    becameCompliantText: string;
    markedExemptedText: string;
    markedUnexemptedText: string;
    assignmentsText: string;
  };
  totals: {
    becameNonCompliantTotal: number;
    becameCompliantTotal: number;
    markedExemptedTotal: number;
    markedNonExemptedTotal: number;
    assignmentsTotal: number;
    riskyNonCompliantTotal: number;
  };
}

export default function RecommendationHistory() {
  const { t } = useTranslation();
  const productOptions =
    useAppSelector((state) => state.app.products)?.map((opt) => ({
      key: opt.displayName,
      value: opt.displayName,
    })) || [];

  const [hoveredDate, setHoveredDate] = useState<string | undefined>();
  const [eventDetailsModalOpen, setEventDetailsModalOpen] = useState(false);
  const [changeLog, setChangeLog] = useState<{ changeLogDate: string; eventsText: TEventLogs } | undefined>();
  // const [timelineRange, setTimelineRange] = useState<{ startDate: Date; endDate: Date } | undefined>({
  //   startDate: moment(new Date()).subtract(90, 'days').toDate(),
  //   endDate: moment(new Date()).toDate(),
  // });

  const [timelineRange, setTimelineRange] = useState<RangeValue<moment.Moment> | undefined>([
    moment(new Date()).subtract(90, 'days'),
    moment(new Date()),
  ]);

  const [activeTabKey, setActiveTabKey] = useState('1');
  const [activeDataTableProps, setActiveDataTableProps] = useState<
    ReturnType<typeof useDataTable<TRecommendationHistory>> | undefined
  >();

  const [activeTableFilterProps, setActiveTableFilterProps] = useState<
    ReturnType<typeof useTableFiltersV2<TRecommendationHistory>> | undefined
  >();

  const {
    actionItems,
    selectedRows,
    selectedRecommendation,
    complianceAction,
    isAdditionalDetailsModalOpen,
    isAssignRecommendationModalOpen,
    isExemptModalOpen,
    isMarkComplianceModalOpen,
    setSelectedRows,
    handleOpenAdditionalDetailsModal,
    toggleAdditionalDetailsModal,
    toggleAssignModalOpen,
    toggleComplianceModal,
    toggleExemptModal,
  } = useRecommendationTableColumnsAndActions({
    onUnExempt: (items) => {
      activeTableFilterProps?.refetch();
      activeDataTableProps?.clearSelection();
    },
  });

  const handleAdditionalDetailsClicked = (props: { changeLogDate: string; eventsText: TEventLogs }) => {
    setChangeLog(props);
    setEventDetailsModalOpen(true);
  };

  const handleAdditionalDetailsModalClose = () => {
    setEventDetailsModalOpen(false);
    setChangeLog(undefined);
  };

  const handleOnRowClick = (row: TRecommendationHistory) => {
    handleOpenAdditionalDetailsModal(row as TRecommendationHistory & TAccountRecommendationExtended);
  };

  const tableCols: TDataColumns<TRecommendationHistory & TAccountRecommendationExtended> = [
    {
      title: 'Time',
      dataIndex: 'date',
      type: 'date',
      render(value, record) {
        const isoDate = parseISO(record.date);
        const formattedDate = formatInTimeZone(isoDate, 'UTC', 'dd/MM/yyyy HH:mm');
        return <>{formattedDate}</>;
      },
      showSortDirections: true,
      width: '10vw',
    },
    {
      title: 'Findings',
      dataIndex: 'findings',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '16vw',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '16vw',
          },
        };
      },
    },
    {
      title: 'Change Type',
      dataIndex: 'changeType',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      align: 'left',
      width: '8vw',
      render: (_, record) => {
        const CapitalizedChangeType = capitalize(record.changeType);
        return <ChangeTypeBadge type={record.changeType as any}>{CapitalizedChangeType}</ChangeTypeBadge>;
      },
    },
    {
      title: 'Change Description',
      dataIndex: 'changeDescription',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '16vw',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '16vw',
          },
        };
      },
    },
    {
      title: 'Change Field',
      dataIndex: 'changeField',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '8vw',
      hideColumn: true,
    },
    {
      title: 'Snapshot Type',
      dataIndex: 'snapshotType',
      allowFiltering: false,
      showSortDirections: true,
      width: '8vw',
      allowDisplay: false,
      hideColumn: true,
    },

    {
      title: 'Product',
      dataIndex: 'productName',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: productOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: '8vw',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '8vw',
          },
        };
      },
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationSeverity',
      showSortDirections: true,
      width: '6vw',
      hideColumn: true,

      render: (_, record) => {
        return (
          <SeverityBadge type={(record.severity as unknown as string).toLowerCase() as any}>
            {record.severity}
          </SeverityBadge>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
      width: '16vw',
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '16vw',
            alignItems: 'center',
          },
        };
      },
      render: (_, record) => {
        const strippedText = stripHtml(record.description);
        return <span style={{ textAlign: 'center', marginTop: '5%' }}>{strippedText}</span>;
      },
    },
    {
      title: () => {
        return (
          <Popover content={<div style={{ maxWidth: '5vw', textAlign: 'center' }}>Validation Method</div>}>
            <div>
              <CloudSyncIcon />
            </div>
          </Popover>
        );
      },
      altLabel: 'Validation Method',
      dataIndex: 'validationMethod',
      allowFiltering: true,
      showSorterTooltip: false,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationValidationMethod',
      showSortDirections: false,
      width: '5vw',
    },
    {
      title: 'Compliance',
      dataIndex: 'isCompliant',
      allowFiltering: true,
      type: 'enum',
      hideColumn: true,
      enumValuesKey: 'BooleanEnum',
      showSortDirections: true,
      width: '5vw',
      align: 'center',
      render(_, record) {
        return (
          <ComplianceStatusBadge isCompliant={record.isCompliant as boolean}>
            {record.isCompliant === null ? 'N/A' : record.isCompliant ? 'Compliant' : 'Non-Compliant'}
          </ComplianceStatusBadge>
        );
      },
    },
    {
      title: 'Exemption',
      dataIndex: 'isExempted',
      allowFiltering: true,
      type: 'boolean',
      hideColumn: true,
      showSortDirections: true,
      width: '4vw',
      align: 'center',
      render(_, record) {
        const isExempted = record.isExempted == 'True';
        return isExempted ? <ExemptionStatusBadge isExempted={isExempted}>Exempted</ExemptionStatusBadge> : 'None';
      },
    },
  ];

  const tabs = [
    {
      label: 'All History',
      key: '1',
      children: (
        <AllHistory
          actionItems={actionItems as any}
          tableColumns={tableCols as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          setActiveTableFilterProps={setActiveTableFilterProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      label: 'Configuration Changes',
      key: '2',
      children: (
        <ConfigurationChanges
          tableColumns={tableCols as any}
          actionItems={actionItems as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          setActiveTableFilterProps={setActiveTableFilterProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      label: 'Assignments',
      key: '3',
      children: (
        <Assignments
          tableColumns={tableCols as any}
          actionItems={actionItems as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          setActiveTableFilterProps={setActiveTableFilterProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      label: 'Exemptions',
      key: '4',
      children: (
        <Exemptions
          tableColumns={tableCols as any}
          actionItems={actionItems as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          setActiveTableFilterProps={setActiveTableFilterProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
  ];

  const userAccountId = useSelector<RootState>((state) => state.user.user?.accountId) as string;

  useEffect(() => {
    if (!timelineRange) return;

    activeTableFilterProps?.refetch();
  }, [timelineRange]);

  return (
    userAccountId && (
      <BasePageWrapper
        title={t('common.recommendationHistory')}
        description="View the history of security recommendation changes, including compliance shifts and exemptions, to understand the evolution of your organization's security posture. Track the dates and details of each state change for comprehensive auditing."
      >
        <PageTitle>Recommendation History</PageTitle>

        <Tabs
          activeKey={activeTabKey}
          items={tabs}
          onChange={(activeKey) => setActiveTabKey(activeKey)}
          destroyInactiveTabPane={true}
        />
        {eventDetailsModalOpen && changeLog && (
          <EventDetailsModal
            changeLogDate={changeLog?.changeLogDate}
            events={changeLog.eventsText}
            open={true}
            onCancel={handleAdditionalDetailsModalClose}
            activeTab={tabs.find((t) => t.key == activeTabKey).label}
          />
        )}
        {isAdditionalDetailsModalOpen && selectedRecommendation && (
          <RecommendationDetailsModal
            open={true}
            recommendationId={selectedRecommendation.recommendationId}
            onCancel={() => {
              toggleAdditionalDetailsModal(false);
            }}
          />
        )}

        {isExemptModalOpen && selectedRows.length > 0 && (
          <ExemptRecommendationModal
            recommendationIds={selectedRows.map((row) => row.recommendationId)}
            open={isExemptModalOpen}
            onCancel={() => toggleExemptModal(false)}
            onOk={() => {
              toggleExemptModal(false);
              activeTableFilterProps?.refetch();
              activeDataTableProps?.clearSelection();
            }}
          />
        )}

        {isMarkComplianceModalOpen && selectedRows.length > 0 && (
          <MarkComplianceModal
            recommendationIds={selectedRows.map((row) => row.recommendationId)}
            open={isMarkComplianceModalOpen}
            complianceAction={complianceAction}
            onCancel={() => toggleComplianceModal(false)}
            onOk={() => {
              toggleComplianceModal(false);
              activeDataTableProps?.clearSelection();
              activeTableFilterProps?.refetch();
            }}
          />
        )}

        {isAssignRecommendationModalOpen && selectedRows.length > 0 && (
          <AssignRecommendationModal
            recommendationIds={selectedRows.map((row) => row.recommendationId)}
            recommendations={selectedRows}
            open={isAssignRecommendationModalOpen}
            onCancel={() => toggleAssignModalOpen(false)}
            onOk={() => {
              toggleAssignModalOpen(false);
              activeDataTableProps?.clearSelection();
              activeTableFilterProps?.refetch();
            }}
          />
        )}
      </BasePageWrapper>
    )
  );
}
