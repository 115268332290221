import { getAccountProducts } from '@app/api/account.api';
import { getGuestSettings, getRolesAndScreens } from '@app/api/app.api';
import { getUserScreenPermissions } from '@app/api/auth.api';
import { getAllLicensePlans, getAllLicenses } from '@app/api/license.api';
import { getAllSubscriptionTypes } from '@app/api/subscriptionType.api';
import { TEnumEntity } from '@app/types/enumEntity';
import { TGroupPermission } from '@app/types/group';
import { TRoleAndScreen } from '@app/types/roleAndScreen';
import { TSubscriptionType } from '@app/types/subscriptionType';
import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface IApplicationState {
  appValues?: Record<string, unknown> | null;
  rolesAndScreens?: TRoleAndScreen[] | null;
  products?: TEnumEntity[] | null;
  accountProducts?: TEnumEntity[] | null;
  licenses?: TEnumEntity[] | null;
  licensePlans?: TEnumEntity[] | null;
  allowedScreensAndActions: { screens: string[]; actions: TGroupPermission[] } | null;
  finishedLoadingValues: boolean;
  subscriptionTypes: TSubscriptionType[] | null;
  appDisplayName?: string;
  pageTitle?: string;
}

const initialState: IApplicationState = {
  appValues: null,
  rolesAndScreens: null,
  products: null,
  accountProducts: null,
  licenses: null,
  licensePlans: null,
  allowedScreensAndActions: null,
  finishedLoadingValues: false,
  subscriptionTypes: null,
  appDisplayName: '',
  pageTitle: undefined,
};

export const loadAppValues = createAsyncThunk('app/loadAppValues', async () => {
  try {
    const responseValues = await Promise.all([
      await getGuestSettings(),

      getAllLicenses(),
      getRolesAndScreens(),
      getUserScreenPermissions(),
      getAllLicensePlans(),
      getAllSubscriptionTypes(),
      getAccountProducts(),
    ]);

    const guestSettings = responseValues[0];
    const licenses = responseValues[1];
    const rolesAndScreens = responseValues[2];
    const screenPermissions = responseValues[3];
    const licensePlans = responseValues[4];
    const subscriptionTypes = responseValues[5];
    const accountProducts = responseValues[6];

    let enumValues: Record<string, unknown> = {};
    if (guestSettings) {
      const enumGroups: Record<string, any> = guestSettings.enums;
      const keys = Object.keys(enumGroups);
      for (const key of keys) {
        const valueKeys = Object.keys(enumGroups[key]);
        const values = [];
        for (const valKey of valueKeys) {
          // allow Deleted enum in user status

          values.push({
            label: valKey,
            value: enumGroups[key][valKey],
          });
        }
        enumValues = {
          ...enumValues,
          [key]: values,
        };
      }
      enumValues = {
        ...enumValues,
        Products: guestSettings?.products.map((product) => ({ value: product.name, label: product.displayName })) || [],
        AccountProducts: accountProducts?.map((product) => ({ value: product.name, label: product.displayName })) || [],
        Licenses: licenses?.map((license) => ({ value: license.name, label: license.displayName })) || [],
        LicensePlans:
          licensePlans?.map((license) => ({ value: license.displayName, label: license.displayName })) || [],
        /** this enum is used for multiselect boolean filters */
        BooleanEnum: [
          { label: 'True', value: 'true' },
          { label: 'False', value: 'false' },
          { label: 'N/A', value: 'null' },
        ],
      };
    }

    return {
      appValues: enumValues,
      products: guestSettings?.products || [],
      appDisplayName: guestSettings?.appDisplayName || '',
      licenses,
      licensePlans,
      rolesAndScreens,
      allowedScreensAndActions: screenPermissions,
      subscriptionTypes,
      accountProducts,
    };
  } catch (error) {
    return null;
  }
});

export const refetchSubscriptionTypes = createAsyncThunk('app/refetchSubscriptionTypes', async () => {
  try {
    const response = await getAllSubscriptionTypes();
    return response;
  } catch (error) {
    return null;
  }
});

export const setPageTitle = createAction<PrepareAction<string>>('app/setPageTitle', (title) => ({
  payload: title,
}));

export const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadAppValues.fulfilled, (state, action) => {
      state.appValues = action.payload?.appValues;
      state.products = action.payload?.products;
      state.appDisplayName = action.payload?.appDisplayName;
      state.licenses = action.payload?.licenses;
      state.licensePlans = action.payload?.licensePlans;
      state.rolesAndScreens = action.payload?.rolesAndScreens;
      state.allowedScreensAndActions = action.payload?.allowedScreensAndActions;
      state.subscriptionTypes = action.payload?.subscriptionTypes;
      state.accountProducts = action.payload?.accountProducts;
      state.finishedLoadingValues = true;
    });
    builder.addCase(refetchSubscriptionTypes.fulfilled, (state, action) => {
      state.subscriptionTypes = action.payload;
    });
    builder.addCase(setPageTitle, (state, action) => {
      state.pageTitle = action.payload;
    });
  },
});

export default appSlice.reducer;
