import React from 'react';
import styled from 'styled-components';

import Logo from '../assets/logo-dark.png';
import MicrosoftIcon from '../assets/images/MicrosoftIcon.png';
import * as S from '../components/auth/LoginForm/LoginForm.styles';
import { joinMicrosoftUser } from '@app/api/auth.api';
import { FaRegCopyright } from 'react-icons/fa6';
import { useAppSelector } from '@app/hooks/reduxHooks';

const EmailText = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  color: #6d6d6d;
  margin: 40px 0px;
`;

const JoinPage: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get('email');
  const token = params.get('token');
  const error = params.get('error');
  const appDisplayName = useAppSelector((state) => state.app.appDisplayName);

  const handleJoin = async () => {
    if (token) {
      const url = await joinMicrosoftUser(token);
      window.location.href = url;
    }
  };

  return (
    <S.LoginContainer>
      <S.LoginBackground />
      <S.LoginForm>
        <S.Logo src={Logo} alt="Logo" />
        <S.LoginFormWrapper>
          <S.LoginTitle>Join {appDisplayName}</S.LoginTitle>
          <S.LoginDescription>
            Use your Microsoft credentials to join the {appDisplayName} management system.
          </S.LoginDescription>
          <S.LoginDescription>{email}</S.LoginDescription>
          <S.ErrorMessage>{error}</S.ErrorMessage>
          <S.SocialLogin variant="outlined" color="dark" size="large" onClick={handleJoin}>
            <S.MicrosoftIcon src={MicrosoftIcon} alt="Microsoft Icon" />
            Login with Microsoft
          </S.SocialLogin>
        </S.LoginFormWrapper>
        <S.CopyrightWrapper>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <FaRegCopyright size={'1rem'} />
          </span>
          {appDisplayName}. All rights reserved.
        </S.CopyrightWrapper>
      </S.LoginForm>
    </S.LoginContainer>
  );
};

export default JoinPage;
