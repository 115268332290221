import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { StyledButton } from '@app/components/common/StyledButton/StyledButton';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { useGetAllLicenses } from '@app/api/license.api';
import { useGetAllProducts } from '@app/api/product.api';
import { TEnumEntity } from '@app/types/enumEntity';
import { useFinalizeAccountSignUp } from '@app/api/account.api';
import { FONT_WEIGHT } from '@app/styles/themes/constants';
import CustomCheckbox from '@app/components/common/CustomInputs/CustomCheckbox';

const AccountInformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
`;

const Title = styled.span`
  font-size: 1.75rem;
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--grey-950);
  margin-top: 1.5rem;
  font-family: Raleway;
`;

const Description = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: var(--grey-700);
  font-family: Raleway;
`;

const CreateAccountBtn = styled(StyledButton)`
  width: 100%;
  background-color: var(--blue-600);
  border-radius: 4px;
  color: white;
  padding: 12px 0px;
  border: none;
  font-weight: 500;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

const FieldLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: var(--grey-950);
`;

const FieldRequiredLabel = styled(FieldLabel)`
  color: red;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 24px;
`;

const FieldBox = styled.div`
  padding: 10px 10px;
  border: 1px solid var(--grey-200);
  border-radius: 4px;
  width: 100%;
`;

const ProductBox = styled.div`
  padding: 10px 10px;
  border: 1px solid var(--grey-100);
  border-radius: 8px;
  width: 100%;
`;

const FieldValue = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const DisabledText = styled(FieldValue)``;

const SelectFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -15px;
`;

const SelectOption = styled(HorizontalAlignedContainer)`
  display: flex;
  align-items: start;
  flex-basis: 50%;
  margin-top: 15px;
  gap: 8px;

  ${({ disabled }: { disabled?: boolean }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;
const AccountNameInput = styled.input`
  background: transparent;
  color: var(--grey-950);
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  width: 100%;

  ::placeholder {
    color: #b0b0b0;
  }
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 0;
`;

export function AccountInformation() {
  const [accountName, setAccountName] = useState('');
  const [selectedFields, setSelectedFields] = useState<number[]>([]);
  const [selectedLicenses, setSelectedLicenses] = useState<number[]>([]);
  const [hasClickedCreate, setHasClickedCreate] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');

  const { mutateAsync: finalizeAccountSetup } = useFinalizeAccountSignUp();

  const [products, setProducts] = useState<TEnumEntity[]>([]);
  const { data: productData, isSuccess: productIsSuccess } = useGetAllProducts();
  useEffect(() => {
    if (productIsSuccess) {
      setProducts(productData);
    }
  }, [productData, productIsSuccess]);

  const handleFieldChecked = (value: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedFields(Array.from(new Set([...selectedFields, value])));
      return;
    }

    const newSelectedFields = selectedFields.filter((field) => field !== value);
    setSelectedFields(newSelectedFields);
  };

  const handleLicensesChecked = (value: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedLicenses(Array.from(new Set([...selectedLicenses, value])));
      return;
    }

    const newSelectedFields = selectedLicenses.filter((field) => field !== value);
    setSelectedLicenses(newSelectedFields);
  };

  const onCreateAccount = async () => {
    setHasClickedCreate(true);

    if (accountName.length === 0) {
      return;
    }
    setIsFetching(true);

    const params = new URLSearchParams(window.location.search);
    const accountId = params.get('accountId') || '';
    try {
      const redirectUrl = await finalizeAccountSetup({
        accountId,
        accountName,
        licenseIds: selectedLicenses,
        productIds: selectedFields,
      });
      window.location.href = redirectUrl;
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <AccountInformationContainer>
      <Title>Create Account</Title>
      <Description>Enter required information for the new account</Description>
      <FieldsContainer>
        <FieldGroup>
          <FieldLabel>Account Name</FieldLabel>
          <FieldBox>
            <AccountNameInput
              type="text"
              placeholder={'Enter a name'}
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </FieldBox>
          {hasClickedCreate && accountName.length === 0 && <FieldRequiredLabel>*Required</FieldRequiredLabel>}
        </FieldGroup>

        <FieldGroup>
          <FieldLabel>Select Products Used In Your Tenant</FieldLabel>
          <ProductBox>
            <SelectFieldsContainer>
              <FieldLabel style={{ marginTop: '15px', color: 'red' }}>
                Premium feature - available for premium accounts only.
              </FieldLabel>
              {products.map((product) => (
                <SelectOption key={product.id} disabled={true}>
                  <CustomCheckbox
                    checked={selectedFields.includes(product.id)}
                    onChange={(e) => handleFieldChecked(product.id, e.target.checked)}
                  />
                  {selectedFields.includes(product.id) ? (
                    <FieldValue>{product.displayName}</FieldValue>
                  ) : (
                    <DisabledText>{product.displayName}</DisabledText>
                  )}
                </SelectOption>
              ))}
            </SelectFieldsContainer>
          </ProductBox>
        </FieldGroup>

        {/* <FieldGroup>
          <FieldLabel>Select Licenses Used In Your Tenant</FieldLabel>
          <FieldBox>
            <SelectFieldsContainer>
              {licenses.map((license) => (
                <SelectOption key={license.id}>
                  <Checkbox
                    checked={selectedLicenses.includes(license.id)}
                    onChange={(e) => handleLicensesChecked(license.id, e.target.checked)}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  {selectedLicenses.includes(license.id) ? (
                    <FieldValue>{license.name}</FieldValue>
                  ) : (
                    <DisabledText>{license.name}</DisabledText>
                  )}
                </SelectOption>
              ))}
            </SelectFieldsContainer>
          </FieldBox>
        </FieldGroup> */}
      </FieldsContainer>
      <ErrorMessage>{error}</ErrorMessage>
      <CreateAccountBtn disabled={isFetching} variant="outlined" color="dark" size="large" onClick={onCreateAccount}>
        Create Account
      </CreateAccountBtn>
    </AccountInformationContainer>
  );
}
